import {
  MAX,
  SPACING_HALF,
  SPACING_QUARTER,
} from 'd2/constants'
import { MergedThemes } from 'd2/types'
import { px } from 'd2/utils/style'
import makeStyles from 'd2/hooks/tss/makeStyles'

type StylesProps = { lineCount: number | undefined }
const styles = (_theme: MergedThemes, { lineCount }: StylesProps) => ({
  clampLines: {
    boxOrient: 'vertical',
    display: '-webkit-box',
    lineClamp: lineCount,
    overflow: 'hidden',
  },
  paddingTopExtraSmall: {
    paddingTop: px(SPACING_QUARTER),
  },
  paddingTopSmall: {
    paddingTop: px(SPACING_HALF),
  },
  truncate: {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: MAX,
  },
} as const)
export default makeStyles<StylesProps>({
  name: 'Truncate',
})(styles)

import {
  Children,
  cloneElement,
  isValidElement,
  memo,
  useMemo,
} from 'react'
import { onlyText } from 'react-children-utilities'
import useStyles from './styles'
import type { Props } from './types'

function clean (text?: string | null): string | null | undefined {
  if (!text) return null
  return text.replace(/\[object Object]/g, '')
}

const Truncate = memo<Props>(({
  children,
  extraSmallPaddingTop,
  lineCount,
  smallPaddingTop,
  title,
}) => {
  const { classes, cx } = useStyles({
    lineCount,
  })
  const titleText: string | null | undefined = useMemo(() => (!!title && clean(onlyText(title))) || null, [title]) // If empty, default to null title.
  return useMemo(() => (
    <>
      { Children.map(
        children,
        (child) => isValidElement(child) && cloneElement(child, {
          // @ts-expect-error TS version 4.8 started complaining about cloneElement's props.
          className: cx(child.props.className,
            {
              [classes.truncate]: Boolean(!lineCount),
              [classes.clampLines]: Boolean(lineCount),
            },
            smallPaddingTop && classes.paddingTopSmall,
            extraSmallPaddingTop && classes.paddingTopExtraSmall,
          ),
          title: titleText ?? null,
        }),
      ) }
    </>
  ), [
    children,
    classes,
    cx,
    extraSmallPaddingTop,
    lineCount,
    smallPaddingTop,
    titleText,
  ])
})

Truncate.displayName = 'Truncate'

export default Truncate
